import React from 'react';

import Founders from 'components/pages/about/founders';
import Privacy from 'components/pages/about/privacy';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const MainPage = () => (
  <MainLayout pageMetadata={SeoMetadata.about}>
    <Founders />
    <Privacy />
  </MainLayout>
);

export default MainPage;
