import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import BackgroundSvg from './images/background.inline.svg';

const items = [
  {
    name: 'Bill Wise',
    link: 'https://www.linkedin.com/in/billwise/',
  },
  {
    name: 'Max Snow',
    link: 'https://www.linkedin.com/in/max-snow-55090118/',
  },
  {
    name: 'Tom Griffiths',
    link: null,
  },
  {
    name: 'Michael Donovan',
    link: null,
  },
];

const backgroundRotateClasses = ['', '-rotate-90', 'rotate-180', 'rotate-90'];

const Founders = () => {
  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "pages/about/founders/founder1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 176)
        }
      }
      image2: file(relativePath: { eq: "pages/about/founders/founder2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 176)
        }
      }
      image3: file(relativePath: { eq: "pages/about/founders/founder3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 176)
        }
      }
      image4: file(relativePath: { eq: "pages/about/founders/founder4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 176)
        }
      }
    }
  `);

  const LinkedInLink = (link) => {
    if (link !== null) {
      return (
        <Link
          to={link}
          className="mt-2 text-sm font-bold text-center uppercase text-primary-1 hover:text-primary-2"
        >
          Linkedin
        </Link>
      );
    } 
      return null;
    
  };

  const images = [image1, image2, image3, image4];

  return (
    <section className="pt-8 pb-20 md:pt-20 md:pb-40">
      <Container>
        <Heading className="text-center" tag="h2" size="xl" theme="black">
          Founders
        </Heading>
        <div className="grid sm:grid-cols-2 mx-auto mt-12 md:mt-20 gap-x-4 gap-y-8 lg:grid-cols-4 lg:gap-x-8">
          {items.map(({ name, link }, index) => (
            <div className="flex flex-col items-center flex-1" key={index}>
              <div className="relative mx-auto">
                <div className="absolute z-10 rounded-full w-44 h-44 top-6 left-3">
                  <GatsbyImage image={getImage(images[index])} alt={name} />
                </div>
                <div className={`transform ${backgroundRotateClasses[index]}`}>
                  <BackgroundSvg />
                </div>
              </div>
              <Heading className="mt-6 font-medium text-center" tag="h3" size="sm">
                {name}
              </Heading>
              {LinkedInLink(link)}
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Founders;
