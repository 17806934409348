import React from 'react';

import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const items = [
  {
    description:
      'When scientists work with behavioral data, they need to show that the benefits of their work will exceed any risks to the people who provided the data.  ',
  },
  {
    description:
      'The same ethical considerations are rarely applied in the commercial use of behavioral data. Yobi aims to put these principles at the forefront of its business model: maximizing benefits while minimizing risks.',
  },
  {
    description:
      'As a steward for behavioral datasets from multiple industries, Yobi uses machine learning technologies to build tools that allow clients to understand the behavior of their customers without violating their privacy. ',
  },
  {
    description:
      'Rather than sharing raw behavioral data, Yobi creates representations of customers that are optimized to be informative but privacy-preserving. This approach gives smaller companies access to the same insight into their customers that big technology companies enjoy, democratizing the marketplace, and will provide non-profit and scientific partners new tools for doing good. ',
  },
  {
    description:
      'Yobi uses only opt-in datasets, subjects data partners, clients, and projects to ethical review, and was created in part to support the scientific use of behavioral data for understanding how human minds and societies work.',
  },
];

const firstRowElements = items.slice(0, 3);
const twoRowElements = items.slice(-2);

const Privacy = () => (
  <section className="relative pt-20 md:pt-32 lg:pt-40 pb-40 -mb-44 gradient-background-gray after:absolute after:-bottom-96 after:gradient-background-gray after:w-full after:h-96">
    <Container>
      <Heading className="text-center" tag="h2" size="lg" theme="black">
        Commitment to Privacy
      </Heading>
      <div className="grid lg:grid-cols-3 gap-8 mt-8 md:mt-16">
        {firstRowElements.map(({ title, description }, index) => (
          <CardWithNumber title={title} description={description} index={index} key={index} />
        ))}
      </div>
      <div className="grid lg:grid-cols-2 gap-8 mt-8">
        {twoRowElements.map(({ title, description }, index) => {
          const cardNumber = firstRowElements.length + index;
          return <CardWithNumber description={description} index={cardNumber} key={cardNumber} />;
        })}
      </div>
    </Container>
  </section>
);

export default Privacy;
